.sidemenu {
  position: fixed;
  left: 0;
  top: 0px;
  width: 65px;
  height: 100%;
  border-right: 1px solid #eeeff3;
  display: flex;
  justify-content: center;
  transition: all 0.3s;
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 190px;
  }
  .link {
    width: 40px;
    height: 40px;
    color: #8390b1;
    background: #f9fafc;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    &.active,
    &:hover {
      color: #f47f20;
    }
    svg {
      width: 28px;
      height: 28px;
    }
  }
}
.close_side_menu {
  transform: translateX(-100%);
}
.arrow_icon_wrapper {
  cursor: pointer;
  position: fixed;
  top: 90px;
  left: 20px;
  z-index: 98;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  box-sizing: content-box;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  &:hover {
    background: #f47f20;
    color: #fff;
  }
}
.content {
  background-color: #f7f8fa;
  height: 100vh;
  overflow: hidden;
}
.page_content {
  margin-top: 70px;
  margin-left: 65px;
  transition: all 0.3s;
  display: grid;
  grid-template-columns: 250px 1fr;
  height: calc(100vh - 70px);
  overflow: hidden;
}
.margin_zero {
  margin-left: 0 !important;
}

.main_content {
  height: calc(100vh - 70px);
  overflow: auto;
  padding: 20px;
}
.side_content {
  align-self: flex-start;
  height: calc(100vh - 70px);
  overflow: auto;
  transition: all 0.5s;

  &:hover::-webkit-scrollbar-thumb {
    background: rgb(202, 202, 202);
    border-radius: 10px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb {
    transition: all 0.5s;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 6px;
    background: transparent;
    border-radius: 10px;
  }
}

.main_wrapper {
  border: 1px solid #eeeff3;
  border-radius: 6px;
  background-color: #fff;
  min-height: 100%;
  .title {
    font-weight: normal;
    font-size: 20px;
    color: #8390b1;
    margin-top: 0;
    margin-bottom: 0px;
    line-height: 1;
    padding: 15px 20px;
    border-bottom: 1px solid #eee;
  }
}
.disable_link {
  pointer-events: none;
}

@media (max-width: 990px) {
  .page_content {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .sidemenu {
    width: 50px;
  }
  .main_content {
    padding: 15px;
    height: calc(100vh - 50px);
  }

  .page_content {
    margin-top: 50px;
    margin-left: 50px;
  }
  .arrow_icon_wrapper {
    top: 55px;
    left: 12px;
  }
}
