.header_search_wrapper {
  width: 200px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 14px;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  .search_placeholder {
    color: #c5cada;
  }
  .search_icon {
    color: #8390b1;
  }
}

// Search popup
.search_popup {
  width: 100%;
  height: 100%;
  background-color: #f1f5fd;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11000;
  overflow: auto;
  &:focus-visible {
    outline: none;
  }
  .cross_icon {
    position: absolute;
    right: 30px;
    top: 30px;
    border: none;
    padding: 5px;
    background-color: transparent;
    line-height: 1;
    transition: all 0.3s;
    svg {
      stroke-width: 2;
      width: 25px;
      height: 25px;
    }
    &:hover {
      color: red;
    }
  }
}

.content_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20vh;
  max-width: 970px;
  margin-left: auto;
  margin-right: auto;
}

.tab_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 50px;
  button {
    &.hover_change {
      &:hover {
        background-color: #f47f20;
        color: #ffffff;
        border: 1px solid #f47f20;
      }
    }
  }
}

@media (max-width: 600px) {
  .header_search_wrapper {
    width: unset;
    height: 40px;
    padding: 0;
    background-color: transparent;
    margin-left: auto;
    margin-right: 10px;
    .search_placeholder {
      display: none;
    }
    .search_icon {
      font-size: 24px;
      color: initial;
    }
  }
  .tab_wrapper {
    flex-direction: column;
    button {
      width: 100% !important;
    }
  }
}
