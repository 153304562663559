.form_wrapper {
  width: 100%;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  z-index: 100;
  // box-shadow: 0px 8px 25px rgb(150 185 255 / 25%);
}

button {
  &.btn_disabled {
    pointer-events: none;
    background: #7a7a7a !important;
    border: 1px solid #7a7a7a !important;
    cursor: not-allowed !important;
  }
}

// dropdown
.dropdown_item {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  gap: 20px;
  .dropdown_head {
    font-weight: 500;
    margin-bottom: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .dropdown_body {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.dropdown_type {
  padding: 1px 10px;
  border-radius: 10px;
  font-size: 12px;
}

// . {
//   color: rgb(93, 95, 239);
// }
