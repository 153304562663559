.grid {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 50px;
  margin-bottom: 3px;
}
.sk1 {
  height: 38px;
}
.grid2 {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  gap: 40px;
}
.grid2_skeletor {
  height: 70px;
}
