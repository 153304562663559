.side_wrapper {
  padding: 20px;
  padding-right: 10px;
}
// user wrapper
.user_wrapper {
  padding-bottom: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar {
  width: 110px;
  height: 110px;
  padding: 4px;
  border-radius: 50%;
  border: 3px solid #c5cada;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}
.indicator {
  width: 100%;
  height: 5px;
  background-color: #8390b1;
}
.name {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #8390b1;
  margin-top: 10px;
}
.email_address {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: #8390b1;
}
// user wrapper end

.link_wrapper {
  ul {
    border: 1px solid #eeeff3;
    border-radius: 10px;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    li:first-child {
      a {
        border-top-left-radius: 10px;
      }
    }
    li:nth-child(2) {
      a {
        border-top-right-radius: 10px;
      }
    }

    li:nth-last-child(2) {
      a {
        border-bottom-left-radius: 10px;
      }
    }
    li:last-child {
      a {
        border-bottom-right-radius: 10px;
      }
    }
    a {
      color: #c3cad9;
      padding-top: 15px;
      padding-bottom: 20px;
      padding-left: 5px;
      padding-right: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border: 0.5px solid #f3f3f3;
      transition: transform 0.2s;
      height: 100%;
      svg {
        width: 22px;
        height: 22x;
        color: #c3cad9;
      }
      &.active {
        svg {
          color: #f47f20;
        }
      }
      &:hover,
      &.active {
        color: #6b7a99;
        background: white;
        box-shadow: 0px 10px 30px rgba(38, 51, 77, 0.03);
        border-radius: 10px;
        transform: scale(1.1);
      }
      span {
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #adb8cc;
      }
      .active {
        z-index: -999;
      }
      .active:hover {
        z-index: 999;
      }
    }
  }
}
