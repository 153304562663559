// Section description
.description_wrapper {
  max-width: 1030px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 450px auto;
  &.full_col {
    grid-template-columns: 1fr;
    gap: 0;
  }
  gap: 15px;
  align-items: center;
  &.usa {
    @media (min-width: 900px) {
      grid-template-columns: 1fr 1fr;
      gap: 120px;
    }
    .description_card_wrapper {
      grid-template-columns: repeat(2, 1fr);
      @media (max-width: 600px) {
        grid-template-columns: 1fr;
      }
    }
  }
}
.description_title {
  font-size: 39px;
}
.custom_desc_title {
  font-size: 32px;
}
.description_sub_title {
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 0;
}
.description_card_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  // align-items: center;
}
.margin_top_60 {
  margin-top: 60px;
}
.description_card {
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}
.description_card_img_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.description_card_segment {
  display: flex;
  flex-direction: column;
  gap: 30px;
  &.align_top {
    align-self: flex-start;
  }
}

@media (max-width: 1024px) {
  .description_wrapper {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .heading_wrapper {
    max-width: 100%;
    align-items: center;
    text-align: center;
  }
  .description_wrapper {
    padding-top: 40px;
  }
}

@media (max-width: 600px) {
  .section_hero {
    padding-top: 30px;
  }
  .description_and_form_section_wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
  .description_wrapper {
    padding-top: 30px;
  }
  .description_title {
    font-size: 28px;
  }
  .custom_desc_title {
    font-size: 28px;
  }
  .description_card_wrapper {
    grid-template-columns: 1fr;
    gap: 30px;
    align-items: center;
  }
  .margin_top_60 {
    margin-top: 0;
  }
}

@media (max-width: 450px) {
  .time_wrapper {
    width: 100%;
    gap: 25px;
    text-align: center;
    flex-direction: column;
  }
  .time_card {
    flex-direction: column;
    width: 100%;
  }
}

@media (min-width: 1921px) {
  .section_hero {
    background-size: cover;
  }
}
