.section_hero {
  background-color: #f6f6fe;
  background-image: url("/assets/images/study/hero_bg.png");
  background-repeat: no-repeat;
  padding-top: 40px;
  padding-bottom: 0px;
  margin-bottom: 80px;
}
.application_week {
  padding-bottom: 60px;
}
.heading_wrapper {
  @media (min-width: 900px) {
    max-width: 570px;
  }
  display: flex;
  flex-direction: column;
  .heading {
    margin-bottom: 25px;
    margin-top: 0;
  }
  .sub_text {
    margin-bottom: 20px;
    text-align: justify;
  }
}
.hero_image_wrapper {
  display: flex;
  justify-content: center;
}
.custom_heading {
  font-size: 38px;
}
.sub_heading {
  margin-top: 0;
  font-weight: 600;
  margin-bottom: 20px;
}
.btn_wrapper {
  max-width: max-content;
  @media (min-width: 992px) {
    padding-bottom: 20px;
  }
}

.time_wrapper {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}
.time_card {
  background: #ffffff;
  box-shadow: 0px 21px 32px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 12px;
  @media (min-width: 768px) {
    min-width: 200px;
  }
  .time_text_bold {
    font-weight: 600;
  }
}

.full_width {
  .heading_wrapper {
    max-width: 1030px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }
  .heading {
    text-align: center;
  }
  .sub_text {
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .section_hero {
    padding-bottom: 40px;
  }
  .time_wrapper {
    text-align: left;
  }
}

@media (max-width: 900px) {
  .section_hero {
    padding-top: 40px;
    margin-bottom: 40px;
  }
  .title {
    margin-bottom: 30px;
  }
}
@media (max-width: 600px) {
  .section_hero {
    padding-top: 30px;
    margin-bottom: 30px;
  }
  .heading_wrapper {
    text-align: center;
    align-items: center;
  }
  .title {
    margin-bottom: 20px;
  }
}

@media (max-width: 450px) {
  .time_wrapper {
    width: 100%;
    gap: 25px;
    text-align: center;
    flex-direction: column;
  }
  .time_card {
    flex-direction: column;
    width: 100%;
  }
}

@media (min-width: 1921px) {
  .section_hero {
    background-size: cover;
  }
}
