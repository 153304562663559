@import "./../../../../../styles//variable";

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  // backdrop-filter: blur(10px);
  // background-color: rgba(255, 255, 255, 0.6);
}
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  height: 70px;
}
.menu,
.notification {
  position: relative;
  width: 50px;
  height: 50px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f8fa;
  border-radius: 50%;
  cursor: pointer;
  svg {
    font-size: 20px;
    color: #f47f20;
  }
  span {
    position: absolute;
    right: 0;
    top: 5px;
    width: 16px;
    height: 16px;
    color: #fff;
    background-color: #f47f20;
    border-radius: 50%;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.left {
  display: flex;
  align-items: center;
  gap: 55px;
}

.right {
  padding-right: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-self: flex-end;
}

// Search main site
.search_main {
  background: #f7f8fa;
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 30px;
  padding: 15px 20px;
  border: none;
  width: 100%;
  font-size: 12px;
  gap: 20px;
}

// search inside student dashboard
.search_inside_dashboard {
  position: relative;
  .search_icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #c3cad9;
    transition: all 0.3s;
    padding: 5px;
    box-sizing: content-box;
    cursor: pointer;
    &:hover {
      color: #f47f20;
    }
  }
  input {
    background: #f7f8fa;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    padding: 15px 20px;
    border: none;
    width: 300px;
    padding-right: 50px;
    &::placeholder {
      font-size: 12px;
      line-height: 20px;
      color: #adb8cc;
    }
  }
}

.message_icon {
  min-width: 20px;
  font-size: 20px;
  cursor: pointer;
  padding-left: 5px;
  box-sizing: content-box;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: #f47f20;
  }
}
.user {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  .avater {
    width: 35px;
    height: 35px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .name {
    color: #8390b1;
    font-weight: 500;
  }
}
.menu_icon {
  display: none;
}
a.active {
  color: #f47f20 !important;
}

.mobile_menu_wrapper {
  padding-top: 60px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  transition: all 300ms ease-in;
  transform: translateY(-100%);
}
.mobile_menu_wrapper.menu_open {
  transition: all 300ms ease-in;
  transform: translateY(0);
}

@media (max-width: 991px) {
  .menu_icon {
    display: block;
    font-size: 30px;
    color: #f47f20;
    margin-right: 10px;
  }
  .right {
    padding-right: 0px;
    gap: 0px;
  }

  .message_icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search_main {
    background: transparent;
    box-shadow: none;
    padding: 0;
    margin-right: 4px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    height: 50px;
  }
  .logo {
    max-width: 100px;
  }
}
