.profile_wrapper {
  display: flex;
  gap: 10px;
  // cursor: pointer;
  .name_wrapper {
    .name {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #424242;
    }
    .id {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #bcbcbc;
    }
  }

  .image_wrapper {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-weight: 500;
    background: #ebeef6;
    border-radius: 5px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}

// .profile_dropdown_parent_wrapper {
//   padding: 0px;
//   min-width: 280px;
// }

.profile_dropdown_wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(150, 185, 255, 0.15);
  border-radius: 10px;
  padding-bottom: 10px;
}
.profile_info {
  padding: 15px 24px;
  padding-bottom: 10px;
}
.menu_list {
  li {
    padding: 0px 12px;
    .link {
      display: block;
      padding: 8px 12px;
      color: #3f4254;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        background: #f8faff;
        border-radius: 6px;
        color: #f47f20;
      }
    }
  }
  li:nth-last-child(2) {
    border-top: 1px solid #e9e9e9;
  }
  .badge {
    background: #fcddec;
    border-radius: 15px;
    font-weight: 600;
    font-size: 9px;
    line-height: 20px;
    text-align: center;
    color: #ef5da8;
    margin-left: 14px;
    padding: 5px 10px;
  }
}
.divider {
  margin-top: 5px;
  margin-bottom: 5px;
  height: 1px;
  width: 100%;
  background-color: #e9e9e9;
}

@media (max-width: 991px) {
  .profile_wrapper {
    .image_wrapper {
      width: 35px;
      height: 35px;
    }
  }
}
