@import "./../../../../../../../styles/variable";

.top_nav {
  background: #ebeef6;
  display: inline-block;
  width: 100%;
  text-align: center;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row;
    justify-content: center;

    li {
      display: list-item;
      list-style: none;
      a {
        padding: 5px 6px;
        font-size: 13px;
        line-height: 1.6;
        display: flex;
        text-decoration: none;
        transition: all 0.3s;
      }
      &.active a,
      &.active a:hover,
      a:hover {
        color: $brand;
        background: $white;
      }
    }
  }
}

.mainHeader_wrapper {
  background: #f1f5fd;
  padding: 15px 0px;
}

.mainHeader {
  display: flex;
  align-items: center;
  gap: 12px;
}

.company_logo {
  img {
    width: 120px;
  }
}

.right_options {
  margin-left: auto !important;
  text-align: right;
  flex-shrink: 0;
  margin-left: 8px;
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    gap: 12px;
    li {
      position: relative;
      display: inline-block;
      // margin-left: 15px;
      list-style: none;
      &:first-child {
        margin-left: 0;
      }

      .user_action {
        width: 25px;
        height: 25px;
        padding: 6px;
        line-height: 1;
        display: block;
        background: $brand;
        border-radius: 50%;
      }
      //   .profile_evaluation {
      //     background: $dark;
      //     color: $white;
      //     border-radius: 5px;
      //     padding: 5px 8px;
      //     text-decoration: none;
      //     display: block;
      //     transition: all 0.3s;
      //     &:hover {
      //       background: $brand;
      //     }
      //   }
    }
  }
}

.cross_icon {
  width: 25px;
  height: 25px;
  color: #f47f20;
  stroke-width: 2;
}
