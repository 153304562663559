.fixed_popup_container {
  z-index: 100;
  position: fixed;
  right: 5px;
  bottom: 90px;
  background-color: #fff;
  padding: 2px;
  border: 1px solid #eee;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  cursor: pointer;
  box-shadow: 0px 8px 25px rgb(150 185 255 / 25%);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 767px) {
  .fixed_popup_container {
    right: 30px;
    bottom: 30px;
    width: 80px;
    height: 80px;
  }
}
