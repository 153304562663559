.header {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
}
.logo {
  margin-right: auto;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  width: 100%;
}
