.form_title_wrapper {
  text-align: center;
  margin-bottom: 30px;
  h2 {
    margin: 0;
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
  }
  p {
    max-width: 670px;
    margin-left: auto;
    margin-right: auto;
  }
}

// Form
.form_group {
  position: relative;
  margin-bottom: 10px;
  label {
    display: inline-block;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
    color: #3f4254;
    position: relative;
    z-index: 1;
    .asterisk {
      color: #eb5757;
    }
  }
  > input {
    height: 50px;
    &[type="radio"],
    &[type="checkbox"] {
      height: auto;
    }
  }
  input,
  textarea {
    background-color: #fff;
    border-radius: 8px;
    padding: 8px 15px;
    border: 1px solid #eee;
    font-size: 14px;
    &.error {
      border: 1px solid #f47f20;
      box-sizing: border-box;
      box-shadow: 0px 4px 15px rgba(244, 127, 32, 0.15);
      border-radius: 8px;
    }
    &::placeholder {
      font-size: 13px;
      line-height: 21px;
      color: #bdbdbd;
    }
  }
}

.dynamic_form_checkbox_wrapper {
  display: inline-flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 30px;
}
.dynamic_form_checkbox_group {
  display: inline-flex !important;
  align-items: center;
  gap: 8px;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}
.error_msg {
  font-size: 12px;
  line-height: 20px;
  color: #ff4b55;
  margin-top: 2px;
}
.register_btn {
  margin-top: 30px;
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background-color: #f47f20;
      border: 1px solid #f47f20;
    }
  }
}

.checkbox_wrapper {
  display: flex;
  flex-wrap: wrap;
  .checkbox_lebel {
    input {
      width: auto;
    }
  }
  .term_link,
  span {
    margin-left: 5px;
  }
}

.term_link {
  color: #3699ff;
}

.checkbox_container_wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  .checkbox_alert_text {
    margin-bottom: 6px;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #202b40;
  }
}

.promise_text {
  margin-top: 20px;
}

// Modal
.success_modal_wrapper {
  padding: 50px;
  padding-top: 120px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .success_title {
    font-weight: 600;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #181c32;
  }
  .success_text {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #b5b5c3;
    margin-bottom: 30px;
  }
}

@media (max-width: 900px) {
  .form_wrapper {
    padding: 30px;
  }
}
@media (max-width: 600px) {
  .form_section {
    padding-top: 30px;
  }
  .form_wrapper {
    padding: 15px;
    h2 {
      font-size: 22px;
    }
    .register_btn {
      margin-top: 15px;
    }
  }
}
