// Highlight section
.section_onlinetest {
}
.title {
  margin-top: 0;
  margin-bottom: 40px;
  text-align: center;
}

.test_card {
  position: relative;
  overflow: hidden;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 50px 80px rgba(150, 185, 255, 0.07),
    0px 15.0735px 46.6781px rgba(150, 185, 255, 0.0504803),
    0px 6.26078px 39.1688px rgba(150, 185, 255, 0.0433045),
    0px 2.2644px 25.5735px rgba(150, 185, 255, 0.0271742);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  .test_card_corner {
    position: absolute;
    right: 0;
    top: 0;
  }
  .test_icon {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 60px;
    }
  }
  .test_title {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #0f1041;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .test_desc {
    font-size: 14px;
    line-height: 30px;
    text-align: justify;
    color: #202b40;
  }
}
.test_card_circle_1 {
  position: absolute;
  right: -25px;
  top: -60px;
  width: 110px;
  height: 100px;
  background-color: #eee;
  border-radius: 50%;
}
.test_card_circle_2 {
  position: absolute;
  right: -32px;
  top: -40px;
  width: 100px;
  height: 100px;
  background-color: #eee;
  border-radius: 50%;
}

@media (max-width: 900px) {
  .section_onlinetest {
    padding-top: 40px;
  }
  .title {
    margin-bottom: 30px;
  }
}
@media (max-width: 600px) {
  .section_onlinetest {
    padding-top: 30px;
  }
  .title {
    margin-bottom: 20px;
  }
}

@media (min-width: 1921px) {
  .section_onlinetest {
    background-size: cover;
  }
}
