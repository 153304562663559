// Form section
.form_wrapper {
  padding-top: 60px;
  padding-bottom: 80px;
  padding-left: 123px;
  padding-right: 123px;
  @media(max-width: 900px){
    padding: 30px;
  }
  max-width: 1030px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  box-shadow: 0px 16px 40px rgba(150, 185, 255, 0.25);
  border-radius: 10px;
}
.form_title_wrapper {
  text-align: center;
  margin-bottom: 30px;
  h2 {
    margin: 0;
    font-size: 36px;
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
  }
  p {
    max-width: 670px;
    margin-left: auto;
    margin-right: auto;
  }
}