@import "../../../../../styles/variable";

.sticky {
  position: sticky;
  z-index: 11;
}
.push {
  margin-left: auto;
}

/*Header Top */
.topHeader {
  display: flex;
  align-items: center;
  background: #ebeef6;
  height: 38px;
}
.top_row {
  display: flex;
  align-items: center;
  overflow: hidden;
  .left_cel {
    width: 30%;
    text-align: left;
  }
  .right_cel {
    margin-left: auto;
  }
  .top_nav {
    display: inline-block;
    width: 40%;
    text-align: center;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-flow: row;
      justify-content: center;

      li {
        display: list-item;
        list-style: none;
        a {
          color: #495474;
          padding: 8px 20px;
          font-size: 14px;
          line-height: 1.6;
          display: flex;
          text-decoration: none;
          transition: all 0.3s;
          @media (max-width: 1260px) {
            font-size: 12px;
          }
        }
        &.active a,
        &.active a:hover,
        a:hover {
          color: $brand;
          background: $white;
        }
      }
    }
  }
}

/* uni-info */
.uni_info {
  display: flex;
  list-style: none;
  margin: 0;
  font-size: 14px;
  li {
    display: inline-block;
    margin-right: 10px;
    padding-left: 10px;
    border-left: 1px solid $dark;
    &:first-child {
      border: none;
      padding-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    a {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #495474;
      text-decoration: none;
      @media (max-width: 1260px) {
        font-size: 12px;
      }
    }
  }
}

.mainHeader {
  // background: $gray-100;
  // box-shadow: 0px 1px 3px #00000029;
  background: #f1f5fd;
  box-shadow: 0px 5px 20px rgba(150, 185, 255, 0.15);
  .row {
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    align-items: center;
    height: 70px;
    .company_logo {
      // flex: 0 0 16%;
      text-align: left;
      img {
        width: 180px;
      }
    }
    .main_nav {
      display: flex;
      align-items: center;
      position: static;
      // flex: 0 0 64%;
      text-align: center;
      color: #8390b1;
      justify-content: center;
      // justify-content: flex-end;
      gap: 40px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          display: inline-block;
          position: relative;
          &.mega {
            position: unset;
            @media (min-width: 768px) {
              position: static;
            }
            & > ul.mega_menu {
              width: 100%;
              min-height: calc(100vh - 46px - 75px);
              .mega_col_wrapper {
                display: flex;
                gap: 15px;
              }
            }
            &:hover ul.mega_menu {
              top: 100%;
            }
            @media (min-width: 1200px) {
              .mega_box {
                max-width: 100%;
                width: 100%;
              }
            }
            .menu_title,
            .menu_title h5 {
              font-weight: bold;
              text-transform: capitalize;
              font-size: 15px;
            }
            .menu_sub_title {
              display: block;
              font-weight: 600;
              margin-top: 10px;
              margin-bottom: 5px;
            }
            .menu_content {
              display: block;
              ul {
                position: relative;
                margin: 0;
                padding: 5px 0;
                left: 0;
                top: 0;
                background: transparent;
                opacity: 1;
                visibility: visible;
                z-index: 0;
                box-shadow: none;
                display: block;
                li {
                  padding: 0;
                  display: list-item;
                  a {
                    padding: 3px 0;
                  }
                }
              }
            }
          }
          a {
            font-size: 15px;
            font-weight: 500;
            // color: #8390b1;
            color: #495474;
            text-decoration: none;
            padding: 25px 12px;
            @media (max-width: 1260px) {
              font-size: 12px;
            }
            @media (max-width: 1366px) {
              padding: 25px 7px;
            }
            display: block;
            transition: color 0.3s;
            &:hover {
              color: $brand;
            }
          }
          &:hover > a {
            color: $brand;
          }
          ul {
            position: absolute;
            left: 0;
            top: 80%;
            min-width: 260px;
            margin: 0;
            border: none;
            padding: 15px 0px 15px 0px;
            text-align: left;
            background: $white;
            visibility: hidden;
            z-index: -10000;
            opacity: 0;
            display: none;
            -webkit-transition: all 300ms ease-in;
            -moz-transition: all 300ms ease-in;
            -ms-transition: all 300ms ease-in;
            -o-transition: all 300ms ease-in;
            transition: all 300ms ease-in;
            -webkit-box-shadow: 0 1px 6px 0 #ebebeb;
            box-shadow: 0 1px 6px 0 #ebebeb;

            li {
              display: list-item;
              position: relative;
              a {
                display: block;
                border: 0 !important;
                background: transparent;
                color: #8390b1;
                border-radius: 0 !important;
                font-size: 14px;
                line-height: 23px;
                font-weight: 400;
                text-transform: capitalize;
                padding: 5px 35px;
                text-decoration: none;
                &:before {
                  position: absolute;
                  bottom: 0;
                  content: "";
                  height: 2px;
                  width: 0;
                  background-color: $danger;
                  -webkit-transition: 0.5s ease;
                  transition: 0.5s ease;
                }
                &:hover:before {
                  @media (min-width: 1200px) {
                    width: 50px;
                    height: 2px;
                    -webkit-transition: 0.5s ease;
                    transition: 0.5s ease;
                  }
                }
                .sub_arrow {
                  position: absolute;
                  top: 50%;
                  margin-top: -17px;
                  left: auto;
                  right: 15px;
                  width: 34px;
                  height: 34px;
                  overflow: hidden;
                  font: bold 16px/34px monospace !important;
                  text-align: center;
                  text-shadow: none;
                  border-radius: 4px;
                  &::before {
                    content: "\f105";
                    font-family: FontAwesome;
                    position: absolute;
                  }
                }
              }
              &:hover > a:before {
                @media (min-width: 1200px) {
                  width: 50px;
                  height: 2px;
                  -webkit-transition: 0.5s ease;
                  transition: 0.5s ease;
                }
              }
              ul {
                left: 100%;
                top: -10px;
              }
              &:hover > ul {
                top: 0%;
                display: block;
              }
            }
          }
          &:hover > ul {
            top: 100%;
            display: block;
            visibility: visible;
            z-index: 10000;
            opacity: 1;
            transition: all 300ms ease-in;
          }
        }
        .lable_nav {
          position: absolute;
          background-color: $danger;
          padding: 2px 7px;
          color: #ffffff;
          top: 8px;
          // text-transform: uppercase;
          width: max-content;
          font-size: 9px;
          left: 50%;
          line-height: 1.3;
          border-radius: 5px;
          &:before {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $danger;
            top: 14px;
            left: 12px;
          }
        }
        .icon_trend {
          color: $danger;
        }
      }
    }

    .right_options {
      // flex: 0 0 20%;
      text-align: right;
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
        padding: 0;
        gap: 12px;
        li {
          position: relative;
          display: inline-block;
          // margin-left: 15px;
          list-style: none;
          &:first-child {
            margin-left: 0;
          }
          .compare {
            line-height: 1;
            position: relative;
            font-size: 24px;
            height: 24px;
            .compare_num {
              padding: 8px;
              width: 15px;
              height: 15px;
              position: absolute;
              right: 0;
              top: -5px;
              right: -6px;
              z-index: 1;
              background: #f47f20;
              text-align: center;
              line-height: 20px;
              border-radius: 50%;
              color: #ffffff;
              font-size: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 1;
            }
          }
          .user_action {
            width: 25px;
            height: 25px;
            padding: 6px;
            line-height: 1;
            display: block;
            background: $brand;
            border-radius: 50%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            @media (max-width: 900px) {
              margin-left: 0px;
            }
            svg {
              fill: #fff;
            }
          }
          .profile_evaluation {
            background: transparent;
            color: #8390b1;
            border-radius: 5px;
            padding: 8px 12px;
            text-decoration: none;
            display: block;
            transition: all 0.3s;
            border: 1px solid $brand;
            &:hover {
              background: $brand;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.open_mobile {
  font-size: 16px;
  min-width: 16px;
  display: none;
}
@media (max-width: 500px) {
  .btn_signin {
    padding: 2px !important;
  }
  .open_mobile {
    display: block;
  }
  .open_desktop {
    display: none;
  }
}
.menu_icon {
  display: none;
}
.btn_profile_evaluation {
  margin-left: 20px;
}

@media (max-width: 1500px) {
  .mainHeader {
    .row {
      justify-content: space-between;
      .company_logo {
        flex: unset;
      }
      .main_nav {
        flex: auto;
        gap: 5px;
      }

      .right_options {
        flex: unset;
        .btn_profile_evaluation {
        }
      }
    }
  }
}

@media (max-width: 1260px) {
  .mainHeader {
    .row {
      .company_logo {
      }
      .main_nav {
      }

      .right_options {
        ul {
          li {
            .profile_evaluation {
              padding: 4px 4px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1140px) {
  .mainHeader {
    .row {
      justify-content: space-between;
      padding: 15px 0;
      .company_logo {
        order: 1;
        flex: unset;
        img {
          width: 120px !important;
        }
      }

      .main_nav {
        order: 2;
        flex: auto;
      }
    }
  }
  .menu_icon {
    display: block;
    order: 3;
    margin-left: 12px;
    width: 25px;
    height: 25px;
    color: $brand;
    stroke-width: 2;
    min-width: max-content;
  }
  .social_links,
  .top_email_address {
    display: none !important;
  }
  .top_phone_num {
    white-space: nowrap;
  }

  .left_cel {
    margin-right: auto;
    width: auto !important;
    text-align: left;
  }

  .main_nav {
    ul {
      display: none !important;
    }
  }

  .top_row {
    .right_cel {
      display: none;
    }
    .top_nav {
      width: auto;
      ul li a {
        padding: 5px 6px;
        font-size: 13px;
      }
    }
  }

  .right_options {
    flex: unset !important;
    order: 2;
    margin-left: auto;
    .btn_profile_evaluation {
      display: none !important;
    }
  }
}

.compare_dropdown_parent {
  position: relative;
  cursor: pointer;
  &:hover .compare_dropdown {
    visibility: visible;
    opacity: 1;
  }
  .compare_dropdown {
    width: 164px;
    padding: 8px 6px;
    background-color: #fff;
    position: absolute;
    box-shadow: 0px 0px 50px rgb(150 185 255 / 15%);
    border-radius: 10px;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    min-width: 120px;
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid #fff;
    }
    a {
      display: flex;
      padding: 8px 12px;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #3f4254;
      transition: all 0.3s;
      &:hover {
        background: #f8faff;
        border-radius: 6px;
        color: #f47f20;
      }
      &:hover .comp_count {
        background-color: #f47f20;
        color: #fff;
      }
      .comp_count {
        width: 22px;
        height: 22px;
        background-color: #0f1041;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        transition: all 0.3s;
      }
    }
  }
}

@media (max-width: 500px) {
  .top_nav ul li a {
    font-size: 11px !important;
    padding: 5px 3px !important;
  }
  .left_cel {
    img {
      display: none;
    }
    span {
      font-size: 11px;
    }
  }
}

@media (max-width: 380px) {
  .top_nav ul li a {
    font-size: 10px !important;
  }
  .left_cel {
    span {
      font-size: 10px;
    }
  }
}
.main_nav {
  .active {
    color: #f47f20 !important;
    // &:before {
    //   position: absolute;
    //   bottom: 0;
    //   content: "";
    //   height: 2px;
    //   width: 0;
    //   background-color: $danger !important;
    //   -webkit-transition: 0.5s ease;
    //   transition: 0.5s ease;
    // }
  }
}
