.popup_wrapper {
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  align-items: center;
  .popup_left {
    padding: 30px;
  }
  .popup_right {
    padding: 30px;
    // border-left: 1px solid #bababa;
    border-left: 1px solid #eeeff3;
  }
  .heading {
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;
    color: #0c1333;
    margin-bottom: 15px;
  }
  .sub_heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #3f4254;
    margin-bottom: 15px;
  }
  .right_heading {
    line-height: 26px;
    color: #828282;
  }
}

.list_wrapper {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  li {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .popup_wrapper {
    grid-template-columns: 1fr;
  }
  .popup_left {
    padding-bottom: 0px !important;
  }
}
