.section_heroform {
  position: relative;
}
.heroform_bg_image_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 400px;
  z-index: -1;
  background-color: #f6f6fe;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
  }
}
.heading_wrapper {
  padding-top: 45px;
  padding-bottom: 40px;
  max-width: 810px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  > * {
    color: #fff;
  }
  .title {
    color: #fff;
  }
  .sub_title {
    p {
      color: #fff;
    }
  }
  &.color_black{
    .title {
      color: initial;
    }
    .sub_title {
      color:initial;
      p {
        color: initial;
      }
    }
  }
}

.form_wrapper {
  padding: 20px;
  @media (min-width: 768px) {
    padding: 40px 75px;
  }
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  box-shadow: 0 16px 40px rgb(150 185 255 / 25%);
  border-radius: 10px;
  .form_title {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
  }
}
