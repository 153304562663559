@import "../../../../styles/variable";

.section_evaluation {
  padding-bottom: 0;
  margin-bottom: -100px;
  position: relative;
  z-index: 1;

  .evaluation_wrapper {
    background-image: url("/assets/images/footer/footer_evaluation_bg.png");
    background-repeat: no-repeat;
    background-size: cover;

    background-color: #f47f20;
    padding: 60px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    border-radius: 10px;

    .mark::before {
      border-top: 3px solid #fff;
    }

    .sub_title,
    .title {
      color: #fff;
    }

    a {
      display: inline-block;
      min-width: max-content;
    }
  }
}

.footer_main {
  background: $footer-bg;
  color: #cccccc;
  padding-bottom: 40px;
  padding-top: 80px;
  &.padding_big {
    padding-top: 180px;
  }

  .row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }

  .fnav_title {
    font-size: 16px;
    font-weight: 600;
    // margin-top: 30px;
    color: $brand;
    margin: 0;
  }

  .fnav_list {
    margin: 20px 0 20px 0;
    padding: 0;
    li {
      margin-bottom: 5px;
      list-style: none;
      p {
        color: $white;
        font-weight: 300;
        padding: 5px 0;
      }
      a {
        text-decoration: none;
        color: $white;
        font-weight: 300;
        padding: 5px 0;
        transition: all 0.3s;
        &:hover {
          color: $brand;
        }
      }
    }
  }
}

.footer_main.mobile {
  display: none;
}

.footer_bottom {
  background-color: #050637;
  padding: 5px 0;
  color: #fff;

  .bottom_left {
    p {
      color: #fff;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bottom_right {
      a {
        display: inline-block;
        padding: 20px;
        color: #fff;
        transition: all 0.3s;
        &:hover {
          color: #f47f20;
        }
      }
    }
  }
}

// address popup
.root {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 10px;
}
.country {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}
.dot {
  display: none;
}

@media (max-width: 900px) {
  .footer_main {
    display: none;
  }
  .footer_main.mobile {
    &.padding_big {
      padding-top: 140px;
    }
  }
  .footer_main.mobile {
    display: block;
    padding-top: 40px;
    padding-bottom: 30px;
    .fnav_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
    }
    .fnav_list {
      margin-top: 5px;
    }
  }
  .footer_top {
    overflow: auto;
    ul {
      white-space: nowrap;
    }
  }
  .container {
    flex-direction: column-reverse;
    .bottom_right {
      a {
        padding: 10px !important;
      }
    }
  }
  // evaluation
  .evaluation_wrapper {
    flex-direction: column;
    gap: 20px;
    padding: 20px !important;
  }
}

@media (max-width: 600px) {
}
.fiiter_link{
  opacity: 80% !important;
  padding: 0;
}