.compare_dropdown_parent {
  position: relative;
  cursor: pointer;
  &:hover .compare_dropdown {
    visibility: visible;
    opacity: 1;
  }
  .compare {
    line-height: 1;
    position: relative;
    font-size: 24px;
    height: 24px;
    .compare_num {
      padding: 8px;
      width: 15px;
      height: 15px;
      position: absolute;
      right: 0;
      top: -5px;
      right: -6px;
      z-index: 1;
      background: #f47f20;
      text-align: center;
      line-height: 20px;
      border-radius: 50%;
      color: #ffffff;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
    }
  }
  .compare_dropdown {
    width: 164px;
    padding: 8px 6px;
    background-color: #fff;
    position: absolute;
    box-shadow: 0px 0px 50px rgb(150 185 255 / 15%);
    border-radius: 10px;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    min-width: 120px;
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid #fff;
    }
    a {
      display: flex;
      padding: 8px 12px;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #3f4254;
      transition: all 0.3s;
      &:hover {
        background: #f8faff;
        border-radius: 6px;
        color: #f47f20;
      }
      &:hover .comp_count {
        background-color: #f47f20;
        color: #fff;
      }
      .comp_count {
        width: 22px;
        height: 22px;
        background-color: #0f1041;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        transition: all 0.3s;
      }
    }
  }
}
