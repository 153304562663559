.heading_wrapper {
  max-width: 882px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 40px;
  .title {
    margin-top: 0;
    margin-bottom: 15px;
  }
  .sub_text {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #828282;
  }
}

// Steps card
.step_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 100%;
  svg {
    width: 64px;
    height: 64px;
  }
  padding: 30px 35px;
  background: #ffffff;
  border: 1px solid #f6f6fe;
  box-shadow: 0px 50px 80px rgba(150, 185, 255, 0.07),
    0px 15px 46px rgba(150, 185, 255, 0.0504803),
    0px 6px 39px rgba(150, 185, 255, 0.0433045),
    0px 2px 25px rgba(150, 185, 255, 0.0271742);
  border-radius: 10px;
  .step_text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #3f4254;
    margin-bottom: 4px;
  }
  .step_desc {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #202b40;
  }
}
.step_card_icon {
  display: flex;
  align-items: center;
  img {
    max-width: 40px;
  }
}

@media (max-width: 900px) {
  .step_card {
    height: 100%;
    &.card_even {
      margin-top: 0px;
    }
  }
}
