//

.mui_paper {
  width: 480px;
  border-radius: 0 !important;
  overflow: hidden;
}
.mui_list {
  padding: 0 !important;
}
.notification_head {
  background-color: #f5f7f9;
  height: 40px;
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
}
.notification_head_left {
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.notification_head_right {
  display: flex;
  align-items: center;
  gap: 8px;
}
.dot {
  width: 5px;
  height: 5px;
  background: #c5c5c5;
  border-radius: 50%;
}
.actions_btn {
  font-size: 12px;
  border: none;
  background-color: transparent;
  color: #2c5cc5;
}

.notification_list_wrapper {
  padding: 8px 0;
  max-height: 60vh;
  overflow: auto;
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(202, 202, 202);
  }
  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
    cursor: pointer;
  }
}
.notification_lists {
  list-style: none;
}
.notification_list {
  display: flex;
  padding: 10px 14px;
  transition: background-color 0.3s;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid #ebeff3;
  }
  &:hover {
    background-color: #e6f4ff;
  }
}

.notification_list_active {
  display: flex;
  padding: 10px 14px;
  background-color: #e6f4ff;
  transition: background-color 0.3s;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid #ebeff3;
  }
}
.list_icon {
  flex-shrink: 0;
  margin-right: 12px;
  width: 30px;
  display: flex;
  justify-content: center;
}
.list_title {
  margin: 0;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #12344d;
}
.list_desc {
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}
.list_text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}
.heighlight {
  font-weight: 500;
  color: #2c5cc5;
}
.list_time {
  flex-shrink: 0;
  font-size: 11px;
  line-height: 18px;
  color: #999999;
  margin-left: auto;
}
